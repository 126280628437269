import React from "react"
// import { Link, useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Carreer/hero"

const DATA = {
  allStrapiJobPosts: {
    nodes: [
      {
        slug: "game-analyst",
        title: "Game Analyst",
        location: "Istanbul, Turkey",
        employment_type: "Remote",
        content: "<p><br></p>",
      },
      {
        slug: "3-d-designer",
        title: "3D Designer",
        location: "Istanbul, Turkey",
        employment_type: "Remote",
        content:
          "<p>We are looking for a 3D Artist to visualize 1946 war era in low poly settings, with tons of fantasy factors. Creating immersive shooting experiences.</p><p>You’ll work within product team to produce cutting-edge visual elements for our products.&nbsp;</p><p>Here we have a highly collaborative environment and we need someone who is excited to work as a part of the team to create beautiful work.</p><p>Together we are always endeavoring to learn more from new challenges, pursue quality, and have a thirst for innovation.</p>",
      },
      {
        slug: "unity-3-d-developer",
        title: "Unity 3D Developer",
        location: "Istanbul, Turkey",
        employment_type: "Remote",
        content: "<p><br></p>",
      },
    ],
  },
}

function Careers() {
  // const data = useStaticQuery(graphql`
  //   {
  //     allStrapiJobPosts(sort: { fields: updated_at, order: DESC }) {
  //       nodes {
  //         slug
  //         title
  //         location
  //         employment_type
  //         content
  //       }
  //     }
  //   }
  // `)

  // console.log(JSON.stringify(data))

  return (
    <Layout>
      <Seo title="Careers" />
      <Hero></Hero>
      <section className="pt-120 pb-120" id="featuredPositions">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="/elements/header-el.png" alt="no surrender" />
                </div>
                <h2 className="section-title">Featured Positions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <ul className="job-list">
                {DATA.allStrapiJobPosts.nodes.map(item => (
                  <li key={item.slug} className="job-preview">
                    {/* <a
                      href={`mailto:hr@nosurrender.studio?subject=Job Application - ${item.title}`}
                      target="_blank"
                      className={"content float-left"}
                    >
                      <h4 className="job-title">{item.title}</h4>
                      <h5 className="company">{item.location}</h5>
                    </a>
                    <a
                      href={`mailto:hr@nosurrender.studio?subject=Job Application - ${item.title}`}
                      target="_blank"
                      className={"btn btn-apply float-sm-right float-xs-left"}
                    >
                      Apply
                    </a> */}
                    {/* TODO: Burayı daha sonra yayına al */}
                    <Link
                      to={`/careers/${item.slug}`}
                      className={"content float-left"}
                    >
                      <h4 className="job-title">{item.title}</h4>
                      <h5 className="company">{item.location}</h5>
                    </Link>
                    <Link
                      to={`/careers/${item.slug}`}
                      className={"btn btn-apply float-sm-right float-xs-left"}
                    >
                      Apply
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Careers
