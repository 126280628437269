import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ParallaxImage from "../Shared/parallaxImage"
import useMove from "../../hooks/useMove"

function Hero() {
  const { pageX, pageY, handleMouseMove } = useMove()
  const heroRef = useRef()

  return (
    // <section
    //   className="hero bg_img"
    //   onMouseMove={handleMouseMove}
    //   ref={heroRef}
    // >
    //   <img src="elements/hero/shape-2.png" alt="no surrender"></img>

    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-45}
    //     heroRef={heroRef}
    //     name={"/hero/el-1.png"}
    //     classes={"el-1"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-25}
    //     heroRef={heroRef}
    //     name={"/hero/el-2.png"}
    //     classes={"el-2"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-50}
    //     heroRef={heroRef}
    //     name={"/hero/el-3.png"}
    //     classes={"el-3"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-35}
    //     heroRef={heroRef}
    //     name={"/hero/el-4.png"}
    //     classes={"el-4"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-60}
    //     heroRef={heroRef}
    //     name={"/hero/el-5.png"}
    //     classes={"el-5"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-40}
    //     heroRef={heroRef}
    //     name={"/hero/el-6.png"}
    //     classes={"el-6"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-45}
    //     heroRef={heroRef}
    //     name={"/hero/el-7.png"}
    //     classes={"el-7"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-35}
    //     heroRef={heroRef}
    //     name={"/hero/el-8.png"}
    //     classes={"el-8"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-50}
    //     heroRef={heroRef}
    //     name={"/hero/el-9.png"}
    //     classes={"el-9"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-35}
    //     heroRef={heroRef}
    //     name={"/hero/el-10.png"}
    //     classes={"el-10"}
    //   />
    //   <ParallaxImage
    //     pX={pageX}
    //     pY={pageY}
    //     movement={-40}
    //     heroRef={heroRef}
    //     name={"/hero/el-11.png"}
    //     classes={"el-11"}
    //   />

    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-8">
    //         <div className="hero__content">
    //           <h2
    //             className="hero__title wow fadeInUp"
    //             data-wow-duration="0.5s"
    //             data-wow-delay="0.5s"
    //           >
    //             CAREERS
    //           </h2>
    //           <p
    //             className="wow fadeInUp"
    //             data-wow-duration="0.5s"
    //             data-wow-delay="0.7s"
    //           >
    //             We create games that are played for years and remembered forever.
    //           </p>
    //           <a
    //             className="cmn-btn wow fadeInUp"
    //             href="#featuredPositions"
    //             data-wow-duration="0.5s"
    //             data-wow-delay="0.7s"
    //           >
    //             FEATURED POSITIONS
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section
      className="hero bg_img"
      onMouseMove={handleMouseMove}
      ref={heroRef}
    >
      <div className="hero__shape">
        <img src="/elements/hero/shape-2.png" alt="no surrender"></img>
      </div>

      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-45}
        heroRef={heroRef}
        name={"/hero/el-1.png"}
        classes={"el-1"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-25}
        heroRef={heroRef}
        name={"/hero/el-2.png"}
        classes={"el-2"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-50}
        heroRef={heroRef}
        name={"/hero/el-3.png"}
        classes={"el-3"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-4.png"}
        classes={"el-4"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-60}
        heroRef={heroRef}
        name={"/hero/el-5.png"}
        classes={"el-5"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-40}
        heroRef={heroRef}
        name={"/hero/el-6.png"}
        classes={"el-6"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-45}
        heroRef={heroRef}
        name={"/hero/el-7.png"}
        classes={"el-7"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-8.png"}
        classes={"el-8"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-50}
        heroRef={heroRef}
        name={"/hero/el-9.png"}
        classes={"el-9"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-10.png"}
        classes={"el-10"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-40}
        heroRef={heroRef}
        name={"/hero/el-11.png"}
        classes={"el-11"}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="hero__content">
              <h2
                className="hero__title wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.5s"
              >
                CAREERS
              </h2>
              <p
                className="wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.7s"
              >
                We believe, the future of gaming  involves the gaming community and social interaction amongst game lovers.<br />
                Passion in our ambition. Challenge is our drive . Be a part of this journey.<br />
                We don’t just make games but we share exciting experiences.<br />
                We drive to match the futures needs.<br />
                Be a part our team.<br />
                Are you passionate to play?
              </p>
              <a
                className="cmn-btn wow fadeInUp"
                href="#featuredPositions"
                data-wow-duration="0.5s"
                data-wow-delay="0.7s"
              >
                FEATURED POSITIONS
               </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
